const navigation = document.getElementById('navigation');
const navigationToggler = navigation?.querySelector('.navbar-toggler');
const dropdown = document.getElementById('navigation-dropdown');

dropdown?.querySelectorAll('.nav-item').forEach((item) => {
	if (!item.classList.contains('dropdown')) {
		item.addEventListener('click', () => {
			toggleDropdown();
		});
	}
});

const toggleDropdown = () => {
	if (navigationToggler?.getAttribute('aria-expanded') == 'true') {
		navigationToggler?.click();
	}
};

// header primary

$('.header-primary__hamburger .hamburger').click(function(e){

    e.preventDefault();

    $(this).toggleClass('hamburger--active');
    $('.header-primary').toggleClass('header-primary--is-open');

});

$(window).scroll(function(){

    let scrollTop = $(this).scrollTop();

    if(scrollTop > 0) {
        $('.header-primary').addClass('header-primary--is-scroll');
    } else {
        $('.header-primary').removeClass('header-primary--is-scroll');
    }

});

$(window).resize(function(){

    let widthWindow = $(this).outerWidth();

    if(widthWindow > 767 && $('.header-primary').hasClass('header-primary--is-open')) {
        $('.header-primary__hamburger .hamburger').removeClass('hamburger--active');
        $('.header-primary').removeClass('header-primary--is-open');
    }

});